.external-link
  line-height: 1.3
  color: $text-secondary
  background-color: #fff
  border-radius: 4px
  box-shadow: 0 1px 0 0 #ddd6cf
  padding: 20px
  height: 200px
  display: flex
  flex-direction: column
  box-sizing: border-box

  &_list
    margin-bottom: 32px

  &_grid
    display: inline-flex
    vertical-align: top
    width: 400px
    margin-right: 24px

    @media $bp.mobile
      width: 250px

.external-link__link
  display: flex
  height: 100%
  justify-content: center
  align-items: top
  color: $text-primary
  text-decoration: none

  @media $bp.mobile
    flex-direction: column
    align-items: center
    text-align: center

.external-link__source-wrapper
  display: flex
  align-items: center
  margin-top: auto

.external-link__image-wrapper
  display: flex
  justify-content: center
  align-items: center
  flex-shrink: 0
  width: 24px
  height: 24px
  margin-right: 10px
  border-radius: 50%
  overflow: hidden
  border: 1px solid rgba(0, 0, 0, 0.08)
  user-select: none

.external-link__image
  height: 100%

.external-link__info
  flex-grow: 1
  width: 100%
  white-space: normal
  flex-flow: column
  display: flex

.external-link__title
  $font-size-large()
  margin-bottom: 8px
  font-family: $font-kazimir
  line-height: 1.3

.external-link__description
  $font-size-small()
  margin-bottom: 8px
  line-height: 1.375
  overflow: auto

.external-link__source
  $font-size-small()
  color: $text-secondary

.external-link__share-icon
  margin-left: auto
  height: 24px
  weight: 24px
