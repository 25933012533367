.author-language-variant
  display: flex

.author-language-variant_default
  background: white
  position: relative
  padding: 24px
  margin-bottom: 8px
  align-items: flex-start

  .author-language-variant__author-name
    $font-size-small()
    color: $text-primary
    line-height: 1
    font-weight: bold
    margin: 1em 0
    text-decoration: none

    &:before
      content: ''
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0

  .author-language-variant__language
    margin-right: 8px

.author-language-variant_dropdown-content
  flex-direction: column
  align-items: flex-start
  padding: 8px 16px

  &:not(:last-child)
    border-bottom: 1px solid $base-default

  @media $bp.tablet, $bp.desktop, $bp.desktopMedium, $bp.desktopLarge // i.e. all sizes except mobile
    &:last-of-type
      padding-bottom: 0

  &:hover
    .author-language-variant__author-name, .meta-counters__count
      color: $text-accent
    .badge_language
      color: $text-accent
      border-color: $text-accent
    .meta-counters__icon-svg
      fill: $text-accent

  .author-language-variant__language
    line-height: 1.3

  .author-language-variant__info
    display: flex
    align-items: center

  .author-language-variant__author-name
    $font-size-x-small()
    font-weight: bold
    text-align: left
    margin: 2px 0
    line-height: 1
    width: 100%
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
