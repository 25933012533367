.quote
  $font-size-large()
  line-height: 28px
  font-family: $font-kazimir
  padding-left: 20px
  border-left: 4px solid $base-line-secondary
  margin: 0 0 16px -24px

  @media $bp.mobile
    padding-left: 12px
    margin: 4px 0 16px -16px
