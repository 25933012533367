.breadcrumbs
  $font-size-x-small()
  margin: 0 0 2px
  padding: 0
  color: $text-secondary
  list-style: none

  &__link
    color: $text-secondary

  &__current
    cursor: default

  &__item
    position: relative
    display: inline-block

    &:not(:last-child)
      margin-right: 16px

      &::after
        content: '•'
        position: absolute
        right: -8px
        transform: translateX(50%)
