.expandable-text
  line-height: 1.5

.expandable-text__text
  display: block

  &_cut
    position: relative
    overflow: hidden

  p
    margin: 0 0 1em 0

.expandable-text__blur
  position: absolute
  bottom: 3px
  right: 0
  width: 50%
  height: 1em

.expandable-text__button
  $font-size-medium()
  display: inline-block
  color: $base-line-primary
  text-decoration: none
  border-bottom: solid 1px $base-line-primary
  cursor: pointer

  &:hover
    color: $text-accent
    border-color: $text-accent
