.emotion
  $font-size-xx-small()
  line-height: 1
  font-weight: bold
  text-transform: uppercase
  color: $text-primary
  display: inline-block
  border-radius: 20px
  border: 1px solid rgba($base-line-secondary, 0.5)
  padding: 4px 10px
  margin: 0 8px 8px 0
  user-select: none
  transition: background-color 0.1s ease-out

.emotion_selectable
  cursor: pointer

.emotion_selected
  color: $text-primary

.emotion__label
.emotion__icon
  position: relative

.emotion__label
  margin-left: 8px
  top: 1px

.emotion__icon
  $font-size-x-large()
  vertical-align: middle
  top: 2px
