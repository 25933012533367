.author-featured
  position: relative
  padding: 44px

  @media $bp.mobile
    margin: 0
    padding: 20px

.author-featured__image-background
.author-featured__darkened-background
  position: absolute
  height: 100%
  width: 100%
  top: 0
  left: 0
  border-radius: 8px

.author-featured__darkened-background
  background: rgba(#000, 0.15)

.author-featured__image-background
  .placeholder-background
    border-radius: 8px

.author-featured__container
  width: 100%

  @media $bp.mobile
    position: relative

.author-featured__content-container
  display: flex
  justify-content: center
  width: 100%
  height: 100%
  max-width: 960px
  margin: auto  

  @media $bp.mobile
    width: 100%
    flex-direction: column-reverse
    align-items: flex-start

.author-featured__fake-link
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 3

.no-js .author-featured
  width: 100vw

.author-featured__book-cover-container
  z-index: 1

  .book-cover
    position: relative
    z-index: 1

.author-featured__text-container
  margin-left: 30px
  width: 400px
  box-sizing: border-box
  white-space: pre-wrap

  @media $bp.tablet
    width: 350px

  @media $bp.mobile
    margin: 0
    width: 100%
    display: flex
    flex-direction: column-reverse
    position: relative

.author-featured__book-title
  position: relative
  font-family: $font-kazimir
  $font-size-x-large()
  color: white
  line-height: 1.18
  margin-bottom: 1rem
  z-index: 1

  @media $bp.mobile
    display: block
    $font-size-large()
    margin-bottom: 0.5rem
    top: 60px
    left: 110px
    width: 65%

.author-featured__annotation
  position: relative
  z-index: 1
  lineHeight = 1.4
  font-family: $font-graphik
  $font-size-medium()
  color: white
  line-height: lineHeight
  overflow-y: hidden
  text-overflow: ellipsis

  @media $bp.mobile
    width: 100%
    $font-size-small()

.author-featured__topic
  text-transform: uppercase
  font-family: $font-graphik
  color: rgba(255, 255, 255, 0.6)
  font-size: 12px
  padding-top: 5px

  @media $bp.mobile
    font-size: 10px
