$user-avatars = 1..6

.users-list
  display: flex
  align-items: center

  @media screen and (max-width: 350px)
    width: calc(100% + 10px)
  @media screen and (max-width: 338px)
    width: calc(100% + 23px)
    margin-left: -10px
  @media screen and (max-width: 326px)
    width: calc(100% + 29px)
    margin-left: -16px

.users-list__avatars_user-page
  display: inline-block
  margin-bottom: 4px
  margin-right: 18px
  display: flex
  margin-right: 11px

.users-list__avatars
  display: flex
  margin-right: 11px

.users-list__user
  position: relative
  display: inline-block
  margin-right: -10px

for i in $user-avatars
  .users-list__user:nth-child({i})
    z-index: length($user-avatars) - i

.users-list__text
  $font-size-x-small()
  display: inline-block
  padding-left: 8px

.users-list__text_resource-page
  line-height: 40px
  margin-left: 10px

  @media $bp.mobile, $bp.tablet
    line-height: 30px

.users-list__text_user-page
  line-height: 16px

.users-list__name
  display: inline-block
  margin-right: 0.3em

  &:last-of-type::after
    content: ""
  &::after
    content: ","

.users-list__message
  margin-right: 0.3em

  &_resource-page
    margin-left: 5px

.users-list__rest
  white-space: nowrap
