.layout-info
  border: 1px solid transparent
  background-color: $base-default

  .horizontal-scroller
    max-width: 624px

  .layout-column_8
    flex: 0 0 624px

.layout-info__wrapper
  display: flex
  flex-wrap: nowrap
  justify-content: center
  margin-left: 8px
  margin-right: 8px

.layout-info__top
  display: table
  @media $bp.mobile
    display: block

  .layout-info__lead
  .layout-info__header
    display: table-cell
    vertical-align: top
    @media $bp.mobile
      display: block

.layout-info__lead
  text-align: right
  @media $bp.mobile
    text-align: left

  @media $bp.desktopMedium, $bp.desktopLarge, $bp.tablet
    padding-right: 24px

  @media $bp.desktop, $bp.mobile
    padding-right: 16px

.book-layout-info
  &__column-balancer
    display: flex
    flex-direction: row
    justify-content: space-around
    position: absolute
    z-index: 0

  &__col
    &-left
      margin-right: 319.5px

      @media screen and (min-width: 1024px) and (max-width: 1247px)
        margin: 0px 353px 0px -40px

    &-right
      margin-left: 321.5px

      @media screen and (min-width: 1024px) and (max-width: 1247px)
        margin-left: 275px

      .layout-column_3
        @media screen and (min-width: 1024px) and (max-width: 1247px)
          width: 170px

      .emotion-rating_medium
        @media screen and (min-width: 1024px) and (max-width: 1247px)
          width: 170px
          padding: 24px 0px

    &-main
      z-index: 1

.author-info 
  > .header-mobile
    display: flex
    margin-bottom: 16px

  .author_buttons
    > .button_primary
      @media $bp.mobile
        width: calc(100% - 64px)!important
      margin-right: 8px
    > .button_inline
        align-self: inherit

  .header_col:first-child
    margin-right: 16px
