.resource-tab__active
  z-index: 0
  position: relative

  @media $bp.desktop, $bp.desktopMedium, $bp.desktopLarge
    margin: 0 auto
    width: fit-content
    width: -moz-fit-content

.resource-tabs
  margin: 0
  padding: 0
  list-style: none

  &__has-many-tabs
    width: 110%

    @media $bp.desktop, $bp.desktopMedium, $bp.desktopLarge
      .tab
        margin-right: 0

  &__item
    display: inline-block
    vertical-align: bottom

    &:hover a:not(.tab_active)
      border-color: transparent

    &-inactive a
      display: inline-block
      box-sizing: border-box
      height: 40px
      text-decoration: none
      border-bottom: 2px solid transparent
      color: black
      cursor: pointer

      &:hover
        border-color: transparent
        color: $text-accent
        cursor: pointer

.is-impressions-tab
  cursor: default

  &:focus
    outline: none
