.topic
  $font-size-xx-small()
  display inline-block
  border-radius: 16px
  border: solid 1px $base-line-secondary
  text-transform uppercase
  margin-right: 4px
  margin-bottom: 4px
  padding: 0 8px
  font-weight: bold
  height: 16px
  line-height: 16px
  color: $text-secondary
  text-decoration: none
  cursor: pointer
  width: fit-content
  white-space: nowrap

  &:hover
    border-color: $action-accent
