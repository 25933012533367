.tab
  $font-size-small()
  display: inline-block
  box-sizing: border-box
  height: 40px
  vertical-align: top
  line-height: 40px
  margin: 0 8px
  padding: 0 8px
  text-decoration: none
  border-bottom: 2px solid transparent
  color: black
  cursor: pointer

  &:hover
    svg
      fill: $text-accent !important

  &:first-of-type
    margin-left: 0

  &.tab_pseudo
    margin-left: 8px

    &:hover
      border-color: $text-accent
      color: $text-accent
      cursor: pointer

.tab_active
  border-color: $text-accent
  color: $text-accent
  cursor: default

  .tab__count
    color: $text-accent

  svg
    fill: $text-accent !important

.tab__count
  color: lighten($text-secondary, 30%)
  padding-left: 4px

.tab_compact
  $font-size-x-small()
  margin-left: 0
  margin-right: 0
  border: none
  font-weight: bold

  &:first-of-type
    padding-left: 0

  @media $bp.mobile
    display: table
    padding-left: 8px
    font-weight: normal
    line-height: 12px
    width: 100%
    border-bottom: 1px solid $base-line-secondary

    > span
      display: table-cell
      vertical-align: middle

      &:first-of-type
        width: 100%

    &:hover
      border-color: $base-line-secondary

    &:first-of-type
      padding-left: 8px

    &:last-of-type
      border-bottom: none

.tab_column
  $font-size-small()
  display: flex
  align-items: center
  height: auto
  width: 100%
  padding-top: 12px
  padding-bottom: 12px
  line-height: normal
  font-weight: normal
  border-bottom: 1px solid $base-line-secondary

  &:hover
    border-bottom: 1px solid $base-line-secondary

  &:last-of-type
    border-bottom: none

  .tab__count
    margin-left: auto

.tab_column
  margin-left: 0

  .tab__content
    display: flex
    justify-content: flex-start
    align-items: center
    font-weight: bold

  .SVGInline
    width: 24px
    height: 24px
    margin-right: 8px

  svg
    fill: $text-secondary
