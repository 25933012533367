.author-info .layout-info
  background: $base-default

.author-info__header
  @media $bp.mobile
    height: 100%
    display: flex
    flex-direction: column
    align-items: flex-start

  .button_inline
    align-self: center
    margin-right: 8px

  .breadcrumbs
    $font-size-small()
  
  .horizontal-scroller
    @media $bp.mobile
      margin-left: -30vw
      max-width: 98vw!important

.author-info__life
  $font-size-xx-small()
  color: $text-secondary
  text-transform: uppercase
  font-weight: bold

.author-info__lead
  display: inline-block
  text-align: center

.heading_2 
  font-size: 24px!important
  line-height: 32px!important

.author_buttons
  display: flex
  align-items: center

.author_button-follow
  max-width: 160px
  margin-right: 8px
