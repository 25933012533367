.author-language-switcher
  position: relative
  display: inline-flex
  color: #9a938d
  border: 1px solid #ddd6cf
  border-radius: 12px
  text-transform: uppercase
  min-height: 20px
  margin-top: 10px

.author-language-switcher__language
  border-right: 1px solid #ddd6cf
  font-size: 10px
  line-height: 10px
  padding: 6px 5px 4px 5px


.author-language-switcher__label
  $font-size-x-small()
  font-weight: bold
  width: 18px

.author-language-switcher__dropdown-content
  columns = 3
  width: ((64 * columns) + (16 * (columns - 1)))px
  max-height: 278px
  overflow-y: auto

  @media $bp.mobile
    width: 100%

.author-language-switcher__label::after
  content: ''
  display: inline-block
  transform: rotate(-135deg)
  position: relative
  width: 5px
  height: 5px
  border-top: 1px solid $base-line-secondary
  border-left: 1px solid $base-line-secondary

  @media $bp.mobile
    left: 4px



